import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select'
import { MenuItem, OutlinedInput } from '@mui/material';



export const RamtunType2Content = ({ramtun, amulafielGvtxam, llowzugulgewyi, mapuzugun, wigkazugun, mapuzugunPvneal}) =>{

    let initLlowzugun ={};
    for (let j = 0; j < ramtun.res_len; j++) {
      initLlowzugun['hemvl'+j.toString()]=''
    }
    
    const [llowzugun, setLlowzugun] = useState(initLlowzugun);
    let maxLengthKvmelu = 0;
    let forArray=-1;
    let forkey = 0; 

    
    ramtun.ramtun.forEach(element => {
        if (maxLengthKvmelu <element.length){
          maxLengthKvmelu = element.lenght
        }
        if (maxLengthKvmelu===0){
          maxLengthKvmelu=5
        }
    });
//    const classes = useStyles();


    const handleChange= (e,indexLlowzugun)=>{
      let auxarray = {...llowzugun}
      auxarray[e.target.name] = e.target.value
      setLlowzugun(auxarray)
    }
    
    useEffect(()=>{},[llowzugun])
    return (
      <>
          {ramtun!==null?
              <Stack direction="column" spacing={4} marginY={4} >

                <Typography variant='h4' sx={{alignSelf:"center"}}>
                  {mapuzugunPvneal? mapuzugun.ramtun2.title:wigkazugun.ramtun2.title}
                </Typography>              
              

              <Grid container columns={ramtun.ramtun.lenght/3} spacing={1} justifyContent={"center"} padding={5}>
                      {ramtun.ramtun? 
                      ramtun.ramtun.map((el)=>{
                            forkey = forkey + 1
                            if (el === "__"){
                              forArray=forArray+1
                            }
                            return(<Grid item 
                                     justifyContent={"center"} alignSelf={"center"} key={forkey}>
                                {
                                  el!=='__'?
                                  <Typography> {el} </Typography>
                                :
                                        
                                   <>     
                                  {ramtun.asList ?
                                    
                                    
                                            <Select
                                              disabled={llowzugulgewyi}
                                              displayEmpty
                                              value={llowzugun[forArray]}
                                              onChange={(e)=>{handleChange(e,forArray)}}
                                              input={<OutlinedInput />}
                                              inputProps={{ 'aria-label': 'Without label' }}
                                              name = {"hemvl"+forArray.toString()}
                                            >
                                              <MenuItem disabled value="">
                                                <em>{mapuzugunPvneal? mapuzugun.ramtun2.zulliafiel:wigkazugun.ramtun2.zulliafiel}</em>
                                              </MenuItem>
                                              {ramtun.kvmelu.map((el) => (
                                                <MenuItem
                                                  key={el}
                                                  value={el}
                                                >
                                                  {el}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                    :
                                    
                                    <TextField 
                                          id={"hemvl"+forArray.toString()} 
                                          value={llowzugun[forArray]} 
                                          label="" 
                                          variant="outlined"  
                                          onChange={(e)=>{handleChange(e,forArray)}} 
                                          sx={{ width: (maxLengthKvmelu?maxLengthKvmelu.toString():"5")+"rem" }} 
                                          disabled={llowzugulgewyi}
                                          name = {"hemvl"+forArray.toString()}
                                          />
                                    
                                  }</>



                                }



                                </Grid>)
                      
                  
              }):<></>

              }
              
              </Grid>
              
                <Button variant="contained"  
                        color="secondary"
                        sx={{alignSelf:"center", justifyContent:"center", marginBottom:"5px", width:"30%"}}
                        onClick={()=>{  
                                       amulafielGvtxam(ramtun,llowzugun);
                                        setLlowzugun(initLlowzugun)
                                      }}>
                  {mapuzugunPvneal? mapuzugun.ramtun2.amulal:wigkazugun.ramtun2.amulal}
                </Button>

              </Stack>
            :
              <>
              {mapuzugunPvneal? mapuzugun.ramtun2.noramtun:wigkazugun.ramtun2.noramtun}
              </>
      
          }
      </>
    )
  
}   

export default RamtunType2Content