import { Button, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import React, {useState,useEffect} from "react";
import Igelal from "../../../../IngelalPage/Igelal";
import httpClient from "../../../../HttpClient";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
const KineWenvy = ({wenvy,mapuzugun, wigkazugun, mapuzugunPvneal, setWenvy,setShowingList,user,chat_id}) =>{
    

    const [msgs, setMsgs]=useState(null)
    const [loadingMsgs,setLoadingMsgs]= useState(true)
    const [wemsg, setWeMsg] = useState('')
    const txipanChatMu = ()=>{
        setShowingList(true)
        setWenvy(null)
    }





    const  amulalChat =  async () =>{
        if (wemsg.length<=0){
            alert('Müli tami wirintukuafiel tami gvtxam')
            return 
        }
        
        
        const resp = await httpClient.post(
                    process.env.REACT_APP_API_URL+'/send_message_chat', 
                    {   
                        "user":user,
                        "user_llowlu":wenvy.eymi2.user_id,
                        "msg":wemsg,
                        "chat_id":wenvy.chat.id
                    },
                    {   
                        headers:
                            {
                                "Access-Control-Allow-Origin": "*"
                            }
                    }
            )
            if (resp.data){
                console.log(resp.status)
            }
            setWeMsg('')
            getWenvy()
        }


    const getWenvy = async  () =>{
        
        let n=10
        let page = 1
        const resp = await httpClient.post(
            
            process.env.REACT_APP_API_URL+'/get_messages_from_chat', 
                {   
                    "chat_id":wenvy.chat.id,  
                    "n":n,
                    "offset":(page - 1 ) * n
                },
                {   
                    headers:
                        {
                            "Access-Control-Allow-Origin": "*"
                        }
                }
            ).then((res) => {//console.log(res, 'success')
        
        
        setMsgs(res.data.chatMsgs)    
        setLoadingMsgs(false)
        
        })
          .catch(error => console.log(error, 'error'))
                    
        }


    useEffect(()=>{

        getWenvy()
    },[])

    useEffect(()=>{},[wemsg])

    return(
        <> 

        <Stack>
            <Grid container >
                
                <Grid item xs={5}>
                    <Button size='small' variant='outlined' onClick={()=>{txipanChatMu()}}>
                        
                        <ArrowBackIcon fontSize='small'/>{mapuzugunPvneal?mapuzugun.wenvy.wiñotual:wigkazugun.wenvy.wiñotual}
                    </Button>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5} sx={{display:"flex", justifyContent:"end",justifyItems:"end"}}>
                    <Button size='small' variant='outlined' onClick={()=>{getWenvy()}}>
                        <RefreshIcon fontSize='small'/>{mapuzugunPvneal?mapuzugun.wenvy.refresh:wigkazugun.wenvy.refresh}
                    </Button>
                </Grid>
                
                {loadingMsgs?
                    <>
                        <Grid item xs={5}>
                        </Grid>
                        <Grid item xs={2}>
                            <Igelal/>
                        </Grid>
                        <Grid item xs={5}>
                        </Grid>
                    </>
                        :
                        <>
                            { msgs.length>0?
                            <>
                                {msgs.map((msg)=>{
                                    let man = msg.user_amullu===user.che.user_id
                                    return(
                                        <>
                                        {man?
                                            <>
                                                <Grid marginY={2}padding={2} item xs={2}key={msg.id+"1"}></Grid><Grid marginY={2} padding={2}item sx={{border:1, borderColor:"skyblue",borderRadius:"2px"}} key={msg.id+"2"} xs={10}>{msg.msg}</Grid></>:
                                            <>
                                                <Grid marginY={2}padding={2}item xs={10} key={msg.id+"1"} sx={{border:1, borderColor:"lime",borderRadius:"2px"}}>{msg.msg}</Grid><Grid marginY={2} padding={2} item key={msg.id+"2"} xs={2}></Grid>
                                            </>
                                            
                                        }
                                        </>
                                    )
                                }

                                )}
                            </>
                            :
                                <>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{padding:5}}>
                                            {mapuzugunPvneal? mapuzugun.wenvy.nochat:wigkazugun.wenvy.nochat}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                </>
                            }
                        <Grid item xs={12}>
                            <TextField  fullWidth
                                        multiline
                                        minrows={5}
                                        maxRows={10} 
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' && event.ctrlKey)
                                               amulalChat()
                                              }}
                                        value={wemsg}
                                        onChange={(e)=>setWeMsg(e.target.value)}
                                        />
                        </Grid>
                        <Grid item xs={7}></Grid>
                        <Grid item xs={5}>
                            <Button size='small' variant='outlined' onClick={()=>{amulalChat()}}>
                                {mapuzugunPvneal?mapuzugun.wenvy.amulal:wigkazugun.wenvy.amulal}
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
        </Stack>
     </>   
    )

}


export default KineWenvy