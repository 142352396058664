import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select'
import { MenuItem, OutlinedInput } from '@mui/material';
import { ZuguluButton } from './ZuguluButton';


//import {useStyles} from './Styles';
//import Loading from '../../../../IngelalPage/Igelal'
//import HttpClient from '../../../../HttpClient';

export const RamtunType3Content = ({ramtun, amulafielGvtxam, llowzugulgewyi, mapuzugun, wigkazugun, mapuzugunPvneal}) =>{


    let initLlowzugun ={};
    for (let j = 0; j < ramtun.res_len; j++) {
      initLlowzugun['hemvl'+j.toString()]=''
    }
    
    const [llowzugun, setLlowzugun] = useState(initLlowzugun);
    let maxLengthKvmelu = 0;
    let forArray=-1;
    let forkey = -1; 

    
    ramtun.ramtun.forEach(element => {
        if (maxLengthKvmelu <element.lenght){
          maxLengthKvmelu = element.lenght
        }
        if (maxLengthKvmelu===0){
          maxLengthKvmelu=5
        }
    });
//    const classes = useStyles();

    const handleChange= (e)=>{
      let auxarray = {...llowzugun}
      auxarray[e.target.name] = e.target.value
      setLlowzugun(auxarray)
    }



    const createArray = (N)=> {
      let newArr = [];
      for (let i = 1; i <= N; i++) {
          newArr.push(i);
      }
      return newArr;
  }

    
    

    
    useEffect(()=>{},[llowzugun])
    return (
      <>
          {ramtun!==null?
              <Stack direction="column" spacing={4} marginY={4} >

                <Typography variant='h4' sx={{alignSelf:"center"}}>
                  {mapuzugunPvneal?mapuzugun.ramtun3.title:wigkazugun.ramtun3.title}
                  
                </Typography>              
              

              <Grid container columns={6} spacing={1} justifyContent={"center"} padding={5}>
                      {ramtun.ramtun? 
                      ramtun.ramtun.map((el)=>{
                            forkey = forkey + 1
                            forArray=forArray+1

                            let  opArray = createArray(ramtun.ramtun.length)
                            return(<Grid item xs={6}
                                     justifyContent={"center"} alignSelf={"center"} key={forkey}>


                                {
                                 <Grid container columns={6} sx={{width:"100", display:"flex", alignItems:"center"}} spacing={3}> 
                                    <Grid item xs={5} sx={{alignItems:"center"}}>                                  
                                    
                                    {el.slice(1,6)==="texto"?
                                    <Typography justifyItems="center"> {el.slice(7)} </Typography>
                                    :
                                    <ZuguluButton  
                                        base64Audio={el.slice(7)} 
                                        pvtxa={false} 
                                        llowzugun={()=>{setLlowzugun()}}
                                        buttonIndex={ramtun.zullifalgelu.indexOf(el)}

                                         />
                                    }
                                    </Grid>
                                  
                                      <Grid item xs={1}>                                  
                                          <Select
                                            disabled={llowzugulgewyi}
                                            displayEmpty
                                            value={llowzugun[forArray]}
                                            name = {"hemvl"+forkey.toString()}
                                            onChange={(e)=>{handleChange(e)}}
                                            input={<OutlinedInput />}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            
                                          >
                                            <MenuItem disabled value="">
                                              <em>{mapuzugunPvneal? mapuzugun.ramtun3.zulliafiel:wigkazugun.ramtun3.zulliafiel}</em>
                                            </MenuItem>


                                              {opArray.map((el2)=>{
                                                return (
                                                
                                                <MenuItem
                                                
                                                key={(forkey).toString()+(el2).toString()}
                                                value={el2}
                                                >
                                                {el2}
                                                </MenuItem>)
                                                }
                                              ) 
                                                  }
                                          </Select>
                                    </Grid>
                                  </Grid>
                                }
                                </Grid>)
              }):<></>
              }
              
              </Grid>
              
                <Button variant="contained"  
                        color="secondary"
                        sx={{alignSelf:"center", justifyContent:"center", marginBottom:"5px", width:"30%"}}
                        onClick={()=>{  
                                    
                                       amulafielGvtxam(ramtun,llowzugun);
                                        setLlowzugun(initLlowzugun)
                                      }}>
                  {mapuzugunPvneal? mapuzugun.ramtun3.amulal:wigkazugun.ramtun3.amulal}
                </Button>

              </Stack>
            :
              <>
                {mapuzugunPvneal? mapuzugun.ramtun3.noramtun:wigkazugun.ramtun3.noramtun}
              </>
      
          }
      </>
    )
  
}   

export default RamtunType3Content