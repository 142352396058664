import { Avatar, Button, Grid, Stack, Typography, Chip } from "@mui/material";
import React, {useState,useEffect} from "react";
import Igelal from "../../../../IngelalPage/Igelal";

const WenvyList = ({wenvyList,mapuzugun, wigkazugun, mapuzugunPvneal,openChat}) =>{
    
    
    useEffect(()=>{},[wenvyList])
    return(
        <Grid container sx={{display:"flex",justifyItems:"start",justifyContent:"start", alignContent:"center",alignItems:"center"}}>
        {!wenvyList?
            <Igelal/>:
            <>
            {wenvyList.map((el)=>{
                    console.log('ELLLL',el)
                    let last_sender_vy;

                    if (el.chat.lastMessageContent.user_amullu === el.iñche.id){
                        console.log('iñche')
                        last_sender_vy = el.iñche2.vy + " "+ el.iñche2.tuwvn
                        console.log(last_sender_vy)
                    }else{
                        console.log('eymi')
                        last_sender_vy = el.eymi2.vy + " "+ el.eymi2.tuwvn
                    }

                    return(
                        <Grid item xs={12} key={'key1'+el.id}>
                            <Grid container onClick={()=>openChat(el)} key={'key2'+el.id}  margin={1} padding={1}sx={{display:"flex", justifyItems:"center", justifyContent:"center", alignItems:"center", alignContent:"center", width:"100%"}}>
                                <Grid item xs={2} sx={{width:"100%"}}>
                                    <Avatar >
                                        {el.avatar}
                                    </Avatar>
                                </Grid> 
                                <Grid item xs={10} key={'key3'+el.id}>
                                    <Typography variant="body1">
                                        <b>{el.eymi2.vy} {el.eymi2.tuwvn} ({el.eymi.username})</b>
                                    </Typography>
                                    <Grid container>
                                    <Grid item xs={12}>

                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Chip label={el.chat.lastMessage}></Chip>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={10}>
                                    <Typography variant="caption"> {el.chat.lastMessageContent? last_sender_vy +" :"+ el.chat.lastMessageContent.msg: null} </Typography>
                                    </Grid>
                                    </Grid>
                                </Grid>
                                        
                                    
                                    
                             </Grid>
                        </Grid>
                                               
                        )
                    }           
                )
            }
            </>
        }
            </Grid>
    )

}


export default WenvyList