import React, {useState,useEffect} from 'react';
import httpClient from '../../HttpClient';
import { Typography,TextField, Stack, Button ,Grid, Alert, Checkbox, FormControlLabel, Snackbar } from '@mui/material';
import { ChumteNewenGeiPass } from '../ChumteNewenGeiPass/ChumteNewenGeiPass';
import Igelal from '../../IngelalPage/Igelal';

const WirintukuwalPage = ({mapuzugun, wigkazugun, mapuzugunPvneal, loadingZugun,setZugun, setLoadingZugun,user}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordkk, setPasswordKK] = useState('')
    const [username, setUsername] = useState('')
    const [passNotEqual, setPassNotEqual] = useState(false)
    const [passMessage, setPassMessage] = useState(false)
    const [credentialsMessage, setCredentialsMessage] = useState(false)
    const [chumalDataMu, setChumalDataMu] = useState(false)
    const [credentialsWork, setCredentialsWork] = useState(true)
    const [passStatus, setPassStatus] = useState(-1)
    const [loading, setLoading] = useState(true) 
    const vertical = 'bottom';
    const horizontal = 'right'; 

    useEffect(()=>{
      //console.log(user)
      console.log('PASO USEFFECT',user)      
      console.log(location)
      if (location.state &&  location.state.redir ){
        console.log('REDIR ?SI ')
        mapuzugun = location.state.mapuzugun
        wigkazugun = location.state.wigkazugun
        mapuzugunPvneal = location.state.mapuzugunPvneal
        loadingZugun = location.loadingZugun
        setLoading(loadingZugun)
      }else {
          console.log('REDIR ?NO ')
          const respz =  httpClient.get(process.env.REACT_APP_API_URL+'/epuzugun').then((res) => {//console.log(res, 'success')
          //console.log(res)
          setZugun(res.data.mapuzugun,res.data.wigkazugun)
          setLoading(false)
          setLoadingZugun(false)
          
          })
            .catch(error => console.log(error, 'error'))

        const resp =  httpClient.get(process.env.REACT_APP_API_URL+'/@me',
                            {headers:{"Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}})
                            .then((res) => {console.log( res,'success');}).catch(error => console.log(error, 'error') )
        
        //console.log(resp)
        
       
        
      }


      ;
  },[])
    

  const validateMail = (email) => {
    const regExp = /^[a-zA-Z0-9._ñÑ]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/
    return regExp.test(email)
     
  }
  const validateUsername = (username) => {
    const regExp = /^[a-zA-Z0-9._ñÑ]+/
    return regExp.test(username)

  }
  const validatePassword = (password) => {
    const min_req = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    const med_req = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    const med_p_req = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    const max_req = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    console.log("pre_length_val")
    if (password.length <8) {
      setPassStatus(-1)
      return false}
    if (max_req.test(password)){
      setPassStatus(4)
      console.log("MAXLEVEL")
    }
    else if (med_p_req.test(password)){
      setPassStatus(3)
      console.log("MIDMAXLEVEL")
    }
    else if (med_req.test(password)){
        setPassStatus(2)
        console.log("MIDMINLEVEL")
    }
    else if (min_req.test(password)){
        setPassStatus(1)
        console.log("MINLEVEL")
    }
    else{
        setPassStatus(0)
        console.log("ERROR")
    }

    return true
  }

  const handleCheck = ()=>{
      setChumalDataMu(!chumalDataMu)
  }

    const registerUser = async ()=>{
      console.log(email,password)
      if (!validateMail(email)){setCredentialsWork(false);}
      if (!validateUsername(username)){setCredentialsWork(false);}
      if (!validatePassword(password)){setCredentialsWork(false);}
      if (password!==passwordkk){
        setPassNotEqual(true);
        setPassMessage(true);
        return
      }else{
        setPassNotEqual(false);
        setPassMessage(false);
      }
      try{
        const resp = await httpClient.post(process.env.REACT_APP_API_URL+'/register',
        {
          username:username,
          email:email,
          password:password,
          pvzvmafiel:chumalDataMu
        });
        console.log(resp.status, resp.statusText);
        window.location.href="/";
      }
      catch(error){
        if (error.response ){
          console.log("valid response");
        }else{
          setCredentialsWork(true);
          return;
          }
        if (error.response.status === 401){
            setCredentialsWork(false);        
            return;
        }
      }
    };

  return (
    
      
        <Grid container spacing={2}
      sx={{
        display:'flex',
        width: '100%',
        height:'100%',
        alignItems: 'center',
        flexDirection:'row',  
      }}
      
     
    >
      <Grid item xs={6} md={7}>
        <Grid container>
        <Grid  item   sx={{
          padding:5,
        justifyItems:'start',
          alignItems:'center',
          height:'100%',
          maxHeight: '80%',
          width:'auto'
          }}
          
      >
          <img src={`${process.env.PUBLIC_URL}/imgs/back.png`} alt='background' style={{width:"70%", maxHeight:"70vh",alignSelf:'center'}}/>
      
        </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={5}>
      <Stack component="form"
      spacing={2}
      noValidate
      autoComplete="off"
      sx={{marginRight:5,
        paddingTop:1,
        paddingRight:5,
        paddingLeft:5,
        paddingBottom:5,
         justifyItems:'center',
          alignItems:'center',
          width: 'auto',
          minWidth: '280px',
          maxWidth: '400px'}}
      
    >
      
      {!loading & !loadingZugun?<> 
      
      
      <img src={`${process.env.PUBLIC_URL}/imgs/logo.svg`} alt='background'  style={{width:'25%',alignSelf:'center'}}/>
        <Typography variant='h4'>
        {mapuzugunPvneal?mapuzugun.wirintukuwal.title:wigkazugun.wirintukuwal.title}
        </Typography>
        <TextField

        fullWidth
          required
          error = {!credentialsWork}
          id="username"
          label="Username"
          value={username}
          onChange={(e)=>setUsername(e.target.value)}
        />
        <TextField

        fullWidth
          required
          error = {!credentialsWork}
          id="email"
          label="Email"
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
        />
        <Grid container sx={{justifyContent:"space-between"}} spacing={1}>
          <Grid item xs={6}>
            <TextField
            fullWidth
              required
              error = {(!credentialsWork | passNotEqual)}
              id="passwordll"
              label={mapuzugunPvneal?mapuzugun.wirintukuwal.kontrasena:wigkazugun.wirintukuwal.kontrasena}
              type={"password"} 
              value={password}
              onChange={(e)=>{setPassword(e.target.value);validatePassword(e.target.value)}}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
            fullWidth
              required
              error = {(!credentialsWork | passNotEqual) }
              id="passwordll"
              label={mapuzugunPvneal?mapuzugun.wirintukuwal.kontrasena:wigkazugun.wirintukuwal.kontrasena}
              type={"password"} 
              value={passwordkk}
              onChange={(e)=>setPasswordKK(e.target.value)}
            />
          </Grid>
        </Grid>
        
        
        <Button variant="contained" onClick={registerUser} fullWidth >{mapuzugunPvneal?mapuzugun.wirintukuwal.wirintukuwalbut:wigkazugun.wirintukuwal.wirintukuwalbut}</Button>
        <a href="/konal" >{mapuzugunPvneal?mapuzugun.wirintukuwal.konmeal:wigkazugun.wirintukuwal.konmeal}</a>
        {passStatus>-1?<ChumteNewenGeiPass level={passStatus} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}    />:null}
        {!credentialsWork? <Snackbar  anchorOrigin={{ vertical, horizontal }} open={credentialsMessage} autoHideDuration={6000} onClose={()=>{setCredentialsMessage(false)}}><Alert severity="warning">{mapuzugunPvneal?mapuzugun.wirintukuwal.welulkan:wigkazugun.wirintukuwal.welulkan}</Alert></Snackbar>:null}
        
        {passNotEqual?  <Snackbar anchorOrigin={{ vertical, horizontal }} open={passMessage} autoHideDuration={6000} onClose={()=>{setPassMessage(false)}}><Alert severity="warning">{mapuzugunPvneal?mapuzugun.wirintukuwal.txvrlai:wigkazugun.wirintukuwal.txvrlai} </Alert></Snackbar>:null}
        </>:<><Igelal/></>}
        </Stack>
        </Grid>
    </Grid>
      

  )
}

export default WirintukuwalPage