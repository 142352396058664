import { Grid } from '@mui/material';


const TypedText = () => {
    
  return (
    <Grid container > <Grid item>Amulwe Kimün</Grid></Grid>
  )
}

export default TypedText