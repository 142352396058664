import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { convertBase64TextToBinary } from '../../utils/utils';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box } from '@mui/material';

export const ZuguluButton = ({base64Audio, pvtxa,mapuzugun, wigkazugun, mapuzugunPvneal})=>{

    const [playing, setPlaying] =useState(false)
    let decodedData = convertBase64TextToBinary(base64Audio)
    let audioBlob = new Blob([decodedData], {type: 'audio/mp3'})
    let audioUrl = URL.createObjectURL(audioBlob)
    
    const audioElement = useRef()
    const zugulpe = async () =>{

    setPlaying(true)
    setTimeout(()=>{audioElement.current.play()},0)
    setTimeout(()=>{setPlaying(false);clearInterval()},(audioElement.current.duration)*1000)
      
      
     
    }

    return(   
    
            <Box  marginTop={pvtxa?5:0}
                  marginBottom={pvtxa?0:2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                    <audio src={audioUrl} ref={audioElement}/>

                    <Button 
                    sx={{ textTransform:"none",
                    justifyContent:"center",
                    justifySelf:"center",
                    minWidth:"10rem",
                    width:"95%",
                    color:playing?"green": pvtxa?"white":"skyblue",
                    minHeight:"4rem" ,
                    fontSize:pvtxa?"30px":"12px",
                    backgroundColor:pvtxa? playing?"skyblue":"#1976d2": "white"
                    }}
                    
                    onClick={zugulpe}
                    
                    variant={pvtxa?"contained":"outlined"} >
                        
                        {mapuzugunPvneal?mapuzugun.zugulgepe:wigkazugun.zugulgepe}  <VolumeUpIcon></VolumeUpIcon> 
                    </Button>
                    
              </Box>



        
    )
}


