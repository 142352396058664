import { makeStyles } from "@mui/styles";


  const useStyles =[   makeStyles({
                        gridStyle:{
                          'backgroundColor':"black",
                          'borderColor':"black",
                          'height':"5px",
                          'width':'100%'
                        }
                        }),        
                      makeStyles({
                        gridStyle:{
                          'backgroundColor':"red",
                          'borderColor':"black",
                          'height':"5px",
                          'width':'100%'
                        }
                        }),        
                      makeStyles({
                        gridStyle:{
                          'backgroundColor':"orange",
                          'borderColor':"black",
                          'height':"5px",
                          'width':'100%'
                        }
                        }),         
                      makeStyles({
                        gridStyle:{
                          'backgroundColor':"yellow",
                          'borderColor':"black",
                          'height':"5px",
                          'width':'100%'
                        }
                        }),
                      makeStyles({
                        gridStyle:{
                          'backgroundColor':"green",
                          'borderColor':"black",
                          'height':"5px",
                          'width':'100%'
                        }
                        }),
                    ]

  

export { useStyles }


