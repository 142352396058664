import React, {useEffect, useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Ruka from "../pages/Ruka/Ruka";
import NotFound from "../pages/Errors/NotFound";
import WirintukuwalPage from "../pages/WirintukuwalPage/WirintukuwalPage";
import ZewmatuafielKontrasena from "../pages/ZewmatuafielKontrasena/ZewmatuafielKontrasena";
import TukupatulanKontrasena from "../pages/TukulpatulanKontrasena/TukulpatulanKontrasena";
import IncheTati from "../pages/IncheTati/IncheTati"
import KonalPage from "../pages/KonalPage/KonalPage";
import "./Router.css";
import { Stack, Grid } from "@mui/material";
import LogkolBar from "../LogkolBar/LogkolBar"
import Igelal from "../IngelalPage/Igelal";
import httpClient from "../HttpClient";




function Router()  {

  const [mapuzugun, setMapuzugun] = useState(null)
  const [wigkazugun, setWigkazugun] = useState(null)
  const [mapuzugunPvneal, setMapuzugunPvneal] = useState(true)
  const [loadingZugun, setLoadingZugun] = useState(true);
  const [page, setPage] = useState(0)

  const changeZugun = ()=>{
    setMapuzugunPvneal(!mapuzugunPvneal)
    
  }

  const setZugun = (mapuzugun_,wigkazugun_)=>{
      setMapuzugun(mapuzugun_)
      setWigkazugun(wigkazugun_)
  }

      useEffect(() => {
          (async ()=>{ 
            //console.log('PRE EPUZUGUN')
            const resp =  httpClient.get(process.env.REACT_APP_API_URL+'/epuzugun').then((res) => {//console.log('success')
            setMapuzugun(res.data.mapuzugun)
            setWigkazugun(res.data.wigkazugun)
            //setLoadingZugun(true)
          })
            .catch(error => console.log(error, 'error'))
            
    })()

        
    
    }, [])
    
    useEffect(()=>{ setLoadingZugun(false)}, [mapuzugun,  wigkazugun])



    return(

<>
      {loadingZugun? <Igelal/>
      : 
      <Stack className="root-div" style={{display:"flex",flexDirection:"column"}} >
      <Grid item xs={12}>
        <LogkolBar changeZugun={changeZugun} apuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} setPage={setPage}  />
      </Grid> 
      <Grid item xs={12} sx={{width:"100%"}}>
      <div className="router-root-div" >
          
            <BrowserRouter >
                <Routes>
                    <Route path="/" exact element={<Ruka mapuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} page={page} setPage={setPage}/>}/>
                    <Route path='/konal'  exact element={<KonalPage mapuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} setZugun={setZugun} setLoadingZugun={setLoadingZugun} />}/>
                    <Route path='/wirintukuwal' exact element={<WirintukuwalPage mapuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} setZugun={setZugun} setLoadingZugun={setLoadingZugun}/>}/>
                    <Route path='/tukulpatulan_kontrasena' exact element={<TukupatulanKontrasena mapuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} setZugun={setZugun} setLoadingZugun={setLoadingZugun}/>}/>
                    <Route path='/zewmatuafiel_kontrasena' exact element={<ZewmatuafielKontrasena mapuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} setZugun={setZugun} setLoadingZugun={setLoadingZugun}/>}>
                        <Route path=":token" element={<ZewmatuafielKontrasena mapuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} setZugun={setZugun} setLoadingZugun={setLoadingZugun}/>} />
                    </Route>
                    <Route path='/tani_email' exact element={<IncheTati mapuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} setZugun={setZugun} setLoadingZugun={setLoadingZugun}/>}>
                        <Route path=":token" element={<IncheTati  mapuzugun={mapuzugun} wigkazugun = {wigkazugun} mapuzugunPvneal={mapuzugunPvneal} loadingZugun={loadingZugun} setZugun={setZugun} setLoadingZugun={setLoadingZugun}/>} />
                    </Route>
                    <Route path='*' element={<NotFound mapuzugunPvneal={mapuzugunPvneal} mapuzugun={mapuzugun} wigkazugun={wigkazugun} />}/>
                </Routes>
            </BrowserRouter>
            
        </div>
      </Grid>
    </Stack>
  }  
</>


        
        
    );
}

export default Router;
