import React, { useState, useEffect } from 'react';
//import { useStyles } from './Styles';
import httpClient from '../../HttpClient';
import { Typography, Grid, Container, Button } from '@mui/material';
import Loading from '../../IngelalPage/Igelal';
//import WeleBar from './components/WeleBar/WeleBar';
import Ramtun from './components/Ramtun/Ramtun';
//import ManBar from './components/ManBar/ManBar';
import Landing from './components/Landing/Landing';
import ChateamRuka from './components/Chateam/ChateamRuka';
import TxokinGvtxamRuka from './components/TxokinGvtxam/TxokinGvtxamRuka';
import { useNavigate } from 'react-router-dom';
import Igelal from '../../IngelalPage/Igelal';


const Ruka = ({mapuzugun, wigkazugun, mapuzugunPvneal, loadingZugun, page, setPage }) => {
   
  const [user, setUser]=useState(null);
  const [loading, setLoading] = useState(true);
  
  const navigate=useNavigate()

  window.onpopstate = () => {
    navigate("/")
    setPage(0);
  }

  const [catchSentenceStyle, setCatchSentenceStyle] = useState({
    fontSize: "7vw",
    lineHeight: "9vw",
    fontWeight: "600",
  });


const updateStyle = () => {
    const vw = window.innerWidth * 0.0065;
    const vh = window.innerHeight * 0.0065;
    const cresponsiveSize = (vh + vw)*4;
  
    setCatchSentenceStyle({
      fontSize: cresponsiveSize + "px",
      lineHeight: cresponsiveSize * 1.1 + "px",
      fontWeight: "100",
    });
  };

useEffect(() => {
    updateStyle();
    window.addEventListener("resize", updateStyle);
    return () => window.removeEventListener("resize", updateStyle);
  }, []);


  useEffect(() => {

          const resp =  httpClient.get(process.env.REACT_APP_API_URL+'/@me',{headers:{"Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}}).then((res) => 
          {
            //console.log(res, 'success')
            //console.log(res.data)
            setUser(res.data)
            setLoading(false)

            })
          .catch((error) => {
              console.log(error, 'error')
              navigate('/konal',{state:{mapuzugun:mapuzugun,wigkazugun:wigkazugun,mapuzugunPvneal:mapuzugunPvneal,loadingZugun:loadingZugun,user:user, redir:true}});
            })
          
    
;

  }, [])


  useEffect(()=>{},[user,loading, mapuzugun,wigkazugun,loadingZugun])
  
  
  
  const txipayal = async ()=>{
      //const resp = 
      await httpClient.get(process.env.REACT_APP_API_URL+'/logout')
      setUser(null)
      navigate('/konal',{state:{mapuzugun:mapuzugun,wigkazugun:wigkazugun,mapuzugunPvneal:mapuzugunPvneal,loadingZugun:loadingZugun,user:user, redir:true}});
      navigate(0)
    }

  // const classes = useStyles();

  const clickHandle= (n)=>{
    setPage(n)}


    return (
      <>{loading? <Igelal/>:
      <div style={{ width: "100%" }}>
        <Container maxWidth="lg" sx={{ display: 'flex', alignItems: 'center', justifyContent:'center'}}>
        
        {loading | loadingZugun | !mapuzugun ? <Loading /> : <>
        <Typography variant='body1'style={catchSentenceStyle}  sx={{padding:3}}>  {mapuzugunPvneal? mapuzugun.ruka.titulo: wigkazugun.ruka.titulo} </Typography>
        </>}
        {(user != null)? <Button variant='outlined' onClick={txipayal}> {mapuzugunPvneal? mapuzugun.txipayal: wigkazugun.txipayal} </Button>:<Loading/>}
        </Container>
        {loading | loadingZugun | !mapuzugun ? <Loading /> : <>

          {(user != null) ?
            <>
              <Grid container columns={8} sx={{justifyContent:"center"}}>
                <Grid item xs={8}>
                  {page===0?
                  <Landing user={user} chuchiPage={clickHandle} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}/>
                  :<>
                    {page===1?
                      <Ramtun  user={user} chuchiPage={clickHandle} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}/>
                      :
                    <>
                      {page===2?
                      <ChateamRuka user={user} chuchiPage={clickHandle} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}/>
                      :
                      <>
                        {page===3?
                          <TxokinGvtxamRuka user={user} chuchiPage={clickHandle} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}/>
                          :
                          null
                          }
                        </>
                      }
                    </>
                    }
                    </>
                  }
                </Grid>
              </Grid>
            </>
            : null
          }
        </>
        }
      </div>
      }
      </>
    )
}

export default Ruka