import React, {useState,useEffect} from 'react';
import httpClient from '../../HttpClient';
import { Typography, Stack, Button ,Grid } from '@mui/material';
import {useParams } from 'react-router-dom';


const IncheTati = ({mapuzugun, wigkazugun, mapuzugunPvneal}) => {
    const [email, setEmail] = useState('')
    const [sendedIfExists, setSendedIfExists] = useState(false)
    let {token} = useParams()

    useEffect(()=>{
      (async ()=>{
        try{
          const resp = await httpClient.get(process.env.REACT_APP_API_URL+'/@me')
          //console.log(resp)
          window.location.href="/"
        }catch{
          console.log('')            
        }

      })();
  },[])
  


  
    const passRequest= async ()=>{
      try{
        const resp = await httpClient.post(process.env.REACT_APP_API_URL+'/confirmation_email',
        {
          token:token,
        });
        //console.log(resp.status, resp.statusText)
        setSendedIfExists(true)
      }
      catch(error){
        if (error.response.status === 401){
            setSendedIfExists(false);        
        }
      }
    };

  return (
    
    <Grid container spacing={2}
    sx={{
      display:'flex',
      width: '100%',
      height:'100%',
      alignItems: 'center',
      flexDirection:'row',
    }}
    
   
  >
    <Grid item xs={6} md={7}>
      <Grid container>
        <Grid  item   sx={{
          padding:5,
        justifyItems:'start',
          alignItems:'center',
          height:'100%',
          maxHeight: '80%',
          width:'auto'
          }}
          
      >
          <img src={`${process.env.PUBLIC_URL}/imgs/back.png`} alt='background' style={{width:"auto", maxHeight:"70vh",alignSelf:'center'}}/>
      
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={6} md={5}>

      <Stack component="form"
      spacing={2}
      noValidate
      autoComplete="off"
      sx={{marginRight:15,
        marginBottom:15,
         justifyItems:'center',
          alignItems:'center',
          width: 'auto',
          minWidth: '280px',
          maxWidth: '330px'}}
      
    >
        {!sendedIfExists?
        <>
            <img src={`${process.env.PUBLIC_URL}/imgs/logo.svg`} alt='background'  style={{width:'30%',alignSelf:'center'}}/>
            <Typography variant='h4'>
                {mapuzugunPvneal ? mapuzugun.inche.titulo: wigkazugun.inche.titulo}
                
            </Typography>
        
        <Button variant="contained" onClick={passRequest} fullWidth >{mapuzugunPvneal ? mapuzugun.inche.inche: wigkazugun.inche.inche}</Button></>:
        <>
            <Typography> {mapuzugunPvneal ? mapuzugun.inche.verificagelu: wigkazugun.inche.verificagelu}</Typography>
            
            <Button variant="contained" onClick={()=>{window.location.href='/konal'}} fullWidth >{mapuzugunPvneal ? mapuzugun.inche.konmealbut: wigkazugun.inche.konmealbut}</Button>
        </>
            }
        </Stack>
      </Grid>
    </Grid>

  )
}

export default IncheTati