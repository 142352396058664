import { Typography,  Card } from '@mui/material'
import React from 'react'
// import { useStyles } from './Sytles/Styles';

const NotFound = ({mapuzugunPvneal}) => {
    
  return (
    <Card sx={{ display:'flex', height:'100%', width:'100%' }}>
      <Typography variant='h3'>
          404 - {mapuzugunPvneal? mapuzugun.e404.msg:wigkazugun.e404.msg}
      </Typography>
    </Card>
  );
}

export default NotFound