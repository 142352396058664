import { Button, Chip, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import httpClient from '../../HttpClient';

const MappedNotification = ({notification,mapuzugunPvneal, setPage, setUnseen, unseen}) => {
    

    const amualNotificationService = (service,noti_id) =>{
            if (service=="chat_message"){
     
                setUnseen(unseen-1)
                httpClient.post(process.env.REACT_APP_API_URL+'/seen_notifications',{"notifications":[noti_id]}).then(
                    (res) => {//console.log(res, 'success')
                        console.log(res)
                    }).catch(
                        error => console.log(error, 'error'))
                setPage(2)
            }
            else if (service=="winolzugun_gvtxam"){
                
                setUnseen(unseen-1)
                httpClient.post(process.env.REACT_APP_API_URL+'/seen_notifications',{"notifications":[noti_id]}).then(
                    (res) => {//console.log(res, 'success')
                        console.log(res)
                    }).catch(
                        error => console.log(error, 'error'))
                setPage(3)
            }
        }

    return (
        <Button variant='outlined' onClick={()=>amualNotificationService(notification.service,notification.id)} sx={{borderRadius:0, textTransform:"inherit", width:"100%"    }}>
            
            
            {notification.service==="chat_message"?
                <Stack direction={"column"}>
                <Grid key={notification.reference_id} container justifyContent={"flex-end"} marginBottom={1}><Chip size='small' label={notification.time_created} >  </Chip> </Grid >
                <Typography color={'black'}>{mapuzugunPvneal? notification.user2 + " we zuguleimu chat mu ":"Tienes un unevo mensaje en el chat de "+ notification.user2 }</Typography>
                </Stack>
            :notification.service == "winolzugun_gvtxam"?
                <Stack>
                <Grid key={notification.reference_id} container justifyContent={"flex-end"} marginBottom={1}><Chip size='small' label={notification.time_created} >  </Chip> </Grid >
                <Typography color={'black'}>{mapuzugunPvneal? notification.user2 + " we tukuli kiñe wiñolzugun tami gvtxam mu":  notification.user2 + " puso una respuesta tu mensaje del foro" }</Typography>
                </Stack>
                
            :
                <Typography color={'black'}>{mapuzugunPvneal?"Nielaimi we zugu":"No tienes notificaiones"}</Typography>
                
            }    
        </Button>
    )
}

export default MappedNotification