import { Grid } from '@mui/material';
import React from 'react';
import CheStatistics from './CheStatistics';
import KonalRamtukan from './KonalRamtukan'
import KonalTxokinGvtxam from './KonalTxokinGvtxam'
import KonalChateam from './KonalChateam'
import CheInfo from './CheInfo';
import { useState,useEffect } from 'react';


const Landing = ({user,chuchiPage,mapuzugun, wigkazugun, mapuzugunPvneal}) => {

    const [paragraphStyle, setStyle] = useState({
        fontSize: "3vw",
        lineHeight: "4vw",
        fontWeight: "100",
      });
      const [catchSentenceStyle, setCatchSentenceStyle] = useState({
        fontSize: "7vw",
        lineHeight: "9vw",
        fontWeight: "1000",
      });


    const updateStyle = () => {
        const vw = window.innerWidth * 0.0065;
        const vh = window.innerHeight * 0.0065;
        const responsiveSize = (vh + vw)*1.5;
        const cresponsiveSize = responsiveSize * 3/1.8;
      
        //Uncomment to apply additional adjustments based on screen ratio
         
         setStyle({
          fontSize: responsiveSize + "px",
          lineHeight: responsiveSize * 1.3 + "px",
          fontWeight: "100",
        });  setCatchSentenceStyle({
          fontSize: cresponsiveSize + "px",
          lineHeight: cresponsiveSize * 1.1 + "px",
          fontWeight: "300",
        });
      };

    useEffect(() => {
        updateStyle();
        window.addEventListener("resize", updateStyle);
        return () => window.removeEventListener("resize", updateStyle);
      }, []);

    

    const clickHandle = (n)=>{
        chuchiPage(n)
    }

    

         

    return  (
            <div style={{justifyContent:"center"}}>
                <Grid container spacing={2} sx={{display:"flex", justifyContent:"center"}}>

                    <Grid item xs={8} md={5}>
                        <CheInfo user={user} paragraphStyle={paragraphStyle} catchSentenceStyle={catchSentenceStyle} />
                    </Grid>

                    <Grid item xs={8} md={5} >
                        <KonalRamtukan amualRamtun={clickHandle} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal} paragraphStyle={paragraphStyle} catchSentenceStyle={catchSentenceStyle}/>
                    </Grid>
                    <Grid item xs={8} md={5} >
                        <KonalChateam amualChateam={clickHandle} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal} paragraphStyle={paragraphStyle} catchSentenceStyle={catchSentenceStyle}/>
                    </Grid>
                    <Grid item xs={8} md={5} >
                        <KonalTxokinGvtxam amualTxokinGvtxam={clickHandle} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal} paragraphStyle={paragraphStyle} catchSentenceStyle={catchSentenceStyle}/>
                    </Grid>
                    <Grid item xs={8} md={10}  >
                        <CheStatistics  user={user}mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal} paragraphStyle={paragraphStyle} catchSentenceStyle={catchSentenceStyle}/>
                    </Grid>

                </Grid>    
            </div>
                )

}

export default Landing