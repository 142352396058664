import React, {useState,useEffect} from 'react';
import httpClient from '../../HttpClient';
import { Typography,TextField, Stack, Button ,Grid, Alert } from '@mui/material';
import Igelal from '../../IngelalPage/Igelal';
import {useLocation, useNavigate} from 'react-router-dom';
import { convertBase64TextToBinary } from '../Ruka/utils/utils';



const KonalPage = ({mapuzugun, wigkazugun, mapuzugunPvneal, loadingZugun,setZugun, setLoadingZugun,user }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [credentialsWork, setCredentialsWork] = useState(true)
    const [loading, setLoading] = useState(true)
    const location = useLocation();
    const navigate  = useNavigate ()

    

    

    //  <a href="/tukulpatulan_kontrasena" >Goymafimi tami kontraseñas?</a>
    useEffect(()=>{
      //console.log(user)
      
      if (location.state &&  location.state.redir ){
        mapuzugun = location.state.mapuzugun
        wigkazugun = location.state.wigkazugun
        mapuzugunPvneal = location.state.mapuzugunPvneal
        loadingZugun = location.loadingZugun
        setLoading(loadingZugun)

      }else {
          const respz =  httpClient.get(process.env.REACT_APP_API_URL+'/epuzugun').then((res) => {//console.log(res, 'success')
          //console.log(res)
          setZugun(res.data.mapuzugun,res.data.wigkazugun)
          setLoading(false)
          setLoadingZugun(true)
          })
            .catch(error => console.log(error, 'error'))
      }
        const resp =  httpClient.get(process.env.REACT_APP_API_URL+'/@me',{headers:{"Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}}).then((res) => {navigate('/',{state:{mapuzugun:mapuzugun,wigkazugun:wigkazugun,mapuzugunPvneal:mapuzugunPvneal,loadingZugun:loadingZugun,user:user, redir:true}});}).catch(error => console.log(error, 'error'))
        //console.log(resp)
        
       
        


      ;
  },[])
  
const navtukulpatulan = () =>{
  console.log('PASO NAVUTKUKAKP')
  navigate('/tukulpatulan_kontrasena',{state:{mapuzugun:mapuzugun,wigkazugun:wigkazugun,mapuzugunPvneal:mapuzugunPvneal,loadingZugun:loadingZugun,user:user, redir:true}});
}

useEffect(()=>{},[mapuzugun, wigkazugun, mapuzugunPvneal, loadingZugun])

    const logInUser = async ()=>{
      try{
        const resp = await httpClient.post(process.env.REACT_APP_API_URL+'/login',
        {
          email:email,
          password:password
        },{headers:{"Access-Control-Allow-Origin": "*"}});
        //console.log(resp)
        //console.log('DEBUG')

        
        navigate('/',{state:{mapuzugun:mapuzugun,wigkazugun:wigkazugun,mapuzugunPvneal:mapuzugunPvneal,loadingZugun:loadingZugun,user:user, redir:true}});
        navigate(0)
      }
      catch(error){
        if (error.response.status === 401){
            setCredentialsWork(false);        
        }
      }
    };

    const keyPress = (e)=>{
      
      if(e.keyCode == 13){
         //console.log('value', e.target.value);
         logInUser()
         
      }
   }

  return (
    <>{ !loading? 
    <Grid container spacing={2}
    sx={{
      display:'flex',
      width: '100%',
      height:'100%',
      alignItems: 'center',
      flexDirection:'row',
      
    }}
    
   
  >
    <Grid item xs={6} md={7}>
      <Grid container>
        <Grid  item   sx={{
          padding:5,
        justifyItems:'start',
          alignItems:'center',
          height:'100%',
          maxHeight: '80%',
          width:'auto'
          }}
          
      >
          <img src={`${process.env.PUBLIC_URL}/imgs/back.png`} alt='background' style={{width:"70%", maxHeight:"70vh",alignSelf:'center'}}/>
      
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={6} md={5}>

      <Stack component="form"
      spacing={2}
      noValidate
      autoComplete="off"
      sx={{marginRight:15,
        marginBottom:15,
         justifyItems:'center',
          alignItems:'center',
          width: 'auto',
          minWidth: '280px',
          maxWidth: '330px'}}
      
    >
      <img src={`${process.env.PUBLIC_URL}/imgs/logo.svg`} alt='background'  style={{width:'30%',alignSelf:'center'}}/>
        <Typography variant='h4'>
            {mapuzugunPvneal ? mapuzugun.konal.titulo: wigkazugun.konal.titulo}
        </Typography>
        <TextField

        fullWidth
          required
          error = {!credentialsWork}
          id="email"
          label="Email"
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter')
               logInUser()
              }}
        />
        <TextField
        fullWidth
          required
          error = {!credentialsWork}
          id="passwordll"
          label="Password"
          type={"password"} 
          value={password}
          onKeyDown={(event) => {
            if (event.key === 'Enter')
               logInUser()
              }}
          onChange={(e)=>setPassword(e.target.value)}
        />
        <Button variant="contained"
              onClick={logInUser}
              
            fullWidth >{mapuzugunPvneal? mapuzugun.konal.konal: wigkazugun.konal.konal}</Button>
                <a href="#" onClick={navtukulpatulan} >{mapuzugunPvneal? mapuzugun.konal.goyman: wigkazugun.konal.goyman}</a>
        {!credentialsWork?<Alert severity="warning"> {mapuzugunPvneal? mapuzugun.konal.credentialsdoesntwork : wigkazugun.konal.credentialsdoesntwork } </Alert>:null}
        </Stack>
      </Grid>
    </Grid>


    :
    <Igelal/>
    }
    </>
  )
}

export default KonalPage