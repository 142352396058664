import { Card, Typography, Avatar, Container,Grid } from "@mui/material"
import React from "react"

const CheInfo = ({user, paragraphStyle, catchSentenceStyle}) => {
    
    return(
        <>
        <Card sx={{margin:2,padding:3}}>
            
           <Container sx={{
                display:'flex',
                flexDirection:'row',
                justifyItems:"flex-start",
                alignItems:"center",
                margin:0,
                padding:0
                


                
                }}>
            <Grid container>
                <Grid item xs={10}>
                        <Avatar   sx={{ width: 55, height: 55, marginX:3, marginBottom:1}} >{user.che.vy.split(" ").map((n)=>n[0]).join(".")}</Avatar>
                </Grid>
                <Grid item xs={12} >
                        <Typography  sx={{ marginX:3}} variant="body1" style={paragraphStyle}>{user.che.vy} {user.che.tuwvn}</Typography>
                </Grid>             
                <Grid item xs={12} >
                <Typography sx={{ marginX:3}} variant="body1" style={paragraphStyle}>{user.email}</Typography>
                </Grid>                
            </Grid>
        </Container>    
    

        




        
        </Card>
        </>
    )
}

export default CheInfo