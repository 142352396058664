import React, { useEffect,  useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { ZuguluButton } from './ZuguluButton';
import { Box } from '@mui/material';
import {imageConverter} from '../../utils/utils';
//import {useStyles} from './Styles';
//import Loading from '../../../../IngelalPage/Igelal'
//import HttpClient from '../../../../HttpClient';

export const RamtunType1Content = ({ramtun, llowzugunAmulafiel, llowzugulgewyi, mapuzugun, wigkazugun, mapuzugunPvneal}) =>{
  
    const [llowzugun, setLlowzugun] = useState(-1);
//    const classes = useStyles();
    useEffect(()=>{},[llowzugun,ramtun])
    

    return (
      <>
          {ramtun!==null?
              <Stack direction="column" spacing={4} marginY={4} >

              {ramtun.ramtun.slice(1,6)==='texto'?
                <Typography variant='h4' sx={{alignSelf:"center"}}>
                  {ramtun.ramtun.slice(7)}
                </Typography>
              :
              <>
              {
                ramtun.ramtun.slice(1,6)==='audio'?
                <Box display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                
                >
                    <Typography variant='h4' sx={{alignSelf:"center", justifySelf:"center"}}>
                    {mapuzugunPvneal? mapuzugun.ramtun1.title:wigkazugun.ramtun1.title}
                      
                    </Typography>
                    <ZuguluButton  base64Audio={ramtun.ramtun.slice(7)} pvtxa={true}/>
                </Box>
                :
                <>
                <Typography variant='h4' sx={{alignSelf:"center"}}>
                  
                </Typography>
                      
                  <Box
                          maxWidth="256px"
                          maxHeight="256px"
                          display="flex"
                          justifyContent={"center"}
                          alignItems={"center"}
                          alignSelf={"center"}
                          justifySelf={"center"}
                          
                          >
                          <img src={ imageConverter(ramtun.ramtun.slice(7))} alt='' style={{"maxHeight":"256px", alignSelf:"center"}}></img>
                    </Box>
                    
                    

                </>
              }
              </>
              }

              <Grid container columns={ramtun.zullifalgelu.lenght*2} spacing={1} justifyContent={"center"} >
                      {ramtun.zullifalgelu? 
                      ramtun.zullifalgelu.map((el)=>{
                            return(<Grid item xs={8} sm={8} md={6} lg={3}
                                     justifyContent={"center"} alignSelf={"center"} key={ramtun.zullifalgelu.indexOf(el)}>
                                {
                            el.slice(1,6)==='texto'?

                                <Button variant={llowzugun === ramtun.zullifalgelu.indexOf(el)? "contained":"outlined"}
                                        sx={{ textTransform:"none",
                                              justifyContent:"center",
                                              justifySelf:"center",
                                              minWidth:"10rem",
                                              width:"95%",
                                              minHeight:"4rem" ,
                                              fontSize:"20px",
                                              borderColor: llowzugun === ramtun.zullifalgelu.indexOf(el)? "blue":"skyblue"}}
                                            
                                        onClick={()=>setLlowzugun(ramtun.zullifalgelu.indexOf(el))}

                                        disabled={llowzugulgewyi}
                                          >
                                  {el.slice(7)}
                                </Button>
                                :
                                <>
                                {
                                  el.slice(1,6)==='audio'?
                                  <Box display="flex"
                                  flexDirection={"column"}
                                  justifyContent="center"
                                  alignItems="center"
                                  marginBottom={5}
                                  
                                  >
                                      <ZuguluButton  
                                        base64Audio={el.slice(7)} 
                                        pvtxa={false} 
                                        llowzugun={()=>{setLlowzugun()}}
                                        buttonIndex={ramtun.zullifalgelu.indexOf(el)}

                                         />
                                      <Button variant={llowzugun === ramtun.zullifalgelu.indexOf(el)? "contained":"outlined"}
                                        sx={{ textTransform:"none",
                                              justifyContent:"center",
                                              justifySelf:"center",
                                              minWidth:"10rem",
                                              width:"95%",
                                              minHeight:"4rem" ,
                                              fontSize:"20px",
                                              borderColor: llowzugun === ramtun.zullifalgelu.indexOf(el)? "blue":"skyblue"}}
                                            
                                        onClick={()=>setLlowzugun(ramtun.zullifalgelu.indexOf(el))}
                                        disabled={llowzugulgewyi}
                                          >
                                  {mapuzugunPvneal? mapuzugun.ramtun1.zulliafiel:wigkazugun.ramtun1.zulliafiel}({ramtun.zullifalgelu.indexOf(el)+1})
                                </Button>
                                  </Box>
                                  :
                                  <>
                                  <Box
                                        maxWidth="256px"
                                        maxHeight="256px"
                                        display="flex"
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        alignSelf={"center"}
                                        justifySelf={"center"}
                                        
                                        >
                                        <img src={ imageConverter(el.slice(7))} alt='' style={{"maxHeight":"256px", alignSelf:"center"}}></img>
                                        <Button variant={llowzugun === ramtun.zullifalgelu.indexOf(el)? "contained":"outlined"}
                                        sx={{ textTransform:"none",
                                              justifyContent:"center",
                                              justifySelf:"center",
                                              minWidth:"10rem",
                                              width:"95%",
                                              minHeight:"4rem" ,
                                              fontSize:"20px",
                                              borderColor: llowzugun === ramtun.zullifalgelu.indexOf(el)? "blue":"skyblue"}}
                                            
                                        onClick={()=>setLlowzugun(ramtun.zullifalgelu.indexOf(el))}
                                        disabled={llowzugulgewyi}
                                          >
                                           {mapuzugunPvneal? mapuzugun.ramtun1.zulliafiel:wigkazugun.ramtun1.zulliafiel} ({ramtun.zullifalgelu.indexOf(el)+1})
                                        </Button>

                                        
                                  </Box>
                                  </>
                                }
                                </>
                                }



                                </Grid>)
                      
                  
              }):<></>

              }
              
              </Grid>
              
                <Button variant="contained"  
                        color="secondary"
                        sx={{alignSelf:"center", justifyContent:"center", marginBottom:"5px", width:"30%"}}
                        onClick={()=>{  
                                       llowzugunAmulafiel(ramtun,ramtun.zullifalgelu[llowzugun]);
                                        setLlowzugun(-1)
                                      }}>
                  {mapuzugunPvneal? mapuzugun.ramtun1.amulal:wigkazugun.ramtun1.amulal}
                </Button>

              </Stack>
            :
              <>{mapuzugunPvneal? mapuzugun.ramtun1.noramtun:wigkazugun.ramtun1.noramtun}
                
              </>
      
          }
      </>
    )
  
}   

export default RamtunType1Content