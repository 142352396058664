import React, {useState, useEffect} from 'react';
import Igelal from '../../IngelalPage/Igelal';
import { styled } from '@mui/material/styles';
import httpClient from '../../HttpClient';
import { Badge, Button, ClickAwayListener, Grid, Stack, Tooltip, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ConstructionOutlined } from '@mui/icons-material';
import MappedNotification from './MappedNotification';
import { tooltipClasses } from '@mui/material/Tooltip';

const Notifications = ({mapuzugunPvneal, loadingZugun,user,loggedIn,setPage} ) => {
    
    const [notifications, setNotifications] = useState(null)
    const [unseen, setUnseen] = useState(null)
    const [beenOpened, setbeenOpened] = useState(false)
    const [loadingNotifications, setLoadingNotifications] = useState(true)
    const [open, setOpen] = useState(false)
    const handleTooltipClose = () => {
        setOpen(false);
      };
    
      const handleTooltipOpen = () => {
        setbeenOpened(true)
        setOpen(true);
      };

      const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          minWidth:100,
          maxWidth: 350,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #1976d2',
          borderRadius: 0
        },
      }));

    useEffect(()=>{
        
            const respz =  httpClient.post(process.env.REACT_APP_API_URL+'/get_notifications',{"n":6,"offset":0}).then(
                (res) => {//console.log(res, 'success')
                    console.log(res)
                    setNotifications(res.data.notifications)
                    setUnseen(res.data.unseen)
                    setLoadingNotifications(false)
                    
                }).catch(
                    error => console.log(error, 'error'))
                    
        
        
    },[])

    useEffect(()=>{
            console.log(notifications)
            },[user,loggedIn,notifications,loadingNotifications])

    return (
        <>
            {!loadingNotifications & loggedIn?
                <>
                    <Stack direction="row" spacing={1}  marginRight={3} alignItems="center">
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div>
                            <HtmlTooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <React.Fragment>
                                        
                                        <Grid container>

                                        {notifications? notifications.map(el=><Grid item margin={1} width={"100%"}  > <MappedNotification notification={el} mapuzugunPvneal={mapuzugunPvneal} setPage={setPage} setUnseen={setUnseen} unseen={unseen} /></Grid>):null}
                                        </Grid>
                                        
                                    </React.Fragment>
                                }
                            >

                                <Button onClick={handleTooltipOpen} variant='text' color='primary'>
                                <Typography sx={{color:"white"}}> {!loadingZugun?<>{mapuzugunPvneal?"We zugu!":"Notificaciones"}</>:null}</Typography>
                                <Badge color="secondary"  variant={beenOpened?'dot':'standart'} badgeContent={unseen?unseen:0}>
                                    

                                    <NotificationsIcon  style={{color:"white"}}/>
                                </Badge>
                                
                                </Button>
                                        
                                        
                            </HtmlTooltip>
                            </div>
                        </ClickAwayListener>
                
                    </Stack>
                </>
                : <Igelal/>
            }
        </>
    )
}


export default Notifications