import { Card, Grid, Button } from "@mui/material";
import React,  {useState, useEffect} from "react";
import Igelal from "../../../../IngelalPage/Igelal";
import WenvyList from "./WenvyList";
import KineWenvy from "./KineWenvy"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import httpClient from "../../../../HttpClient";

const ChateamRuka = ({user,chuchiPage, mapuzugun, wigkazugun, mapuzugunPvneal}) =>{

    const [loadingWenvy, setLoadingWenvy] = useState(false)//true)

    const [showingLista, setShowingLista] = useState(true)
    const [wenvyList, setWenvyList] = useState([])
    const [wenvy, setWenvy] = useState(null)
    
    const openChat = (wenvy) =>{
        setWenvy(wenvy)
        //console.log("openchat")
        //console.log(wenvy)
        setShowingLista(false)
    }

    const getWenvy = async  () =>{
        let n=5
        let page = 1
        const resp = await httpClient.post(
            process.env.REACT_APP_API_URL+'/get_chats', 
                {   
                    "user":user,
                    "n":n,
                    "offset":(page - 1 ) * n
                },
                {   
                    headers:
                        {
                            "Access-Control-Allow-Origin": "*"
                        }
                }
            ).then((res) => {//console.log(res, 'success')
        //console.log(res)
        
        setWenvyList(res.data.chats)    
        setLoadingWenvy(false)
        
        })
          .catch(error => console.log(error, 'error'))
                    
        }



    useEffect(()=>{
        getWenvy()
    },[])

    useEffect(()=>{
    },[wenvyList,wenvy])


    return (
        <Grid container spacing={1} sx={{ justifyContent:"center",justifyItems:"start"}} >
            <Grid item xs={8} paddingBottom={3}>
                <Button size='small' variant='contained' onClick={()=>{chuchiPage(0)}}>
                    <ArrowBackIcon fontSize='small'/>{mapuzugunPvneal?mapuzugun.back:wigkazugun.back}
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Card sx={{display:"flex", justifyItems:"start",justifyContent:"start"}}>
                        {!loadingWenvy?
                        <>
                            {showingLista | !wenvy ?
                                <WenvyList wenvyList={wenvyList} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal} openChat={openChat}/>
                            :
                                <KineWenvy wenvy={wenvy} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal} setShowingList={setShowingLista} setWenvy={setWenvy} user={user}/>
                            }
                        </>
                        : 
                            <Igelal/>
                        }
                </Card>
            </Grid>

        </Grid>
            )
                    
}

export default ChateamRuka
