import React,{useEffect,useState} from 'react';
import Grid from '@mui/material/Grid';
import  RamtunType1Content from './RamtunType1Content';
import  RamtunType2Content from './RamtunType2Content';
import  RamtunType3Content from './RamtunType3Content';
import httpClient from '../../../../HttpClient';
//import Loading from '../../../../IngelalPage/Igelal';
//import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card'
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Igelal from '../../../../IngelalPage/Igelal';

const Ramtun = ({chuchiPage, user,mapuzugun, wigkazugun, mapuzugunPvneal}) => {

   
 
  

  const [loadingRamtun, setLoadingRamtun] = useState(true);
  const [kvmeyStatus, setKvmeyStatus] = useState(0);
  const [ramtunList, setRamtunList] = useState();
  const [fewlaRamtun, setFewlaRamtun] =  useState(0);
  const [loadingAsses, setLoadingAsses] = useState(false)

  
  

  let getRamtun = async () => {
    try {
      setLoadingRamtun(true);
      const resp = await httpClient.post(process.env.REACT_APP_API_URL+'/get_ramtun', {
        
        "user":user,
        "n":5,
        "level":1
      },{headers:{"Access-Control-Allow-Origin": "*"}});
      if (resp.data ) {
        setRamtunList([])
        setRamtunList(resp.data.ramtun)
      }
      setLoadingRamtun(false);
    } catch {
      setLoadingRamtun(false);
    }
  }

  const registerWinolzugun = async (kvmi,ramtun)=>{
    try {
        const resp = await httpClient.post(process.env.REACT_APP_API_URL+'/register_winolzugun', {
        "user":user,
        "ramtun_id":ramtun.id,
        "kvmi":kvmi
      },{headers:{"Access-Control-Allow-Origin": "*"}});
      if (resp.data ) {
        console.log(resp.status)
      }
    } catch {
      console.log('no se pudo registar la respuesta');
      
    } finally{ return }
  }


  const kvpalechikamtxipan = ()=>{
    //registerWinolzugun(kvmeyStatus,ramtunList[fewlaRamtun])

    setKvmeyStatus(0)
    if (fewlaRamtun===ramtunList.length-1){
      chuchiPage(0)
    }else{
      setFewlaRamtun(fewlaRamtun+1)} 
  }
  
  const amulafielLlowzugun = async (ramtun, llowzugun)=>{
      

      if (llowzugun===-1){
        alert('Müli tami zulliafiel kiñe llowzugun')
        return
      }



      
      const resp = await httpClient.post(
        process.env.REACT_APP_API_URL+'/asses_ramtun', 
            {   
                "user":user,
                "ramtun_id":ramtun.id,
                "llowzugun": llowzugun

            },
            {   
                headers:
                    {
                        "Access-Control-Allow-Origin": "*"
                    }
            }
        ).then((res) => {//console.log(res, 'success')
          if (res.data.resp){
            setKvmeyStatus(1)
          }else{
            setKvmeyStatus(-1)
          }
              
    
    
    })
      .catch(error => console.log(error, 'error'))
  } 

  const amulafielGvtxam = async (ramtun, llowzugun)=>{

    
    if (!llowzugun){
      alert('Müli tami tukulal kiñe llowzugun')
        return; 
    }
    let lentotal=0;
    for (let index = 0; index < ramtun.res_len; index++) {
      lentotal= lentotal+ llowzugun["hemvl"+(index).toString()].length
    }
    if (lentotal===0){
      return;
    }
  
    

    
      const resp = await httpClient.post(
        process.env.REACT_APP_API_URL+'/asses_ramtun', 
            {   
                "user":user,
                "ramtun_id":ramtun.id,
                "llowzugun": Object.values(llowzugun)

            },
            {   
                headers:
                    {
                        "Access-Control-Allow-Origin": "*"
                    }
            }
        ).then((res) => {//console.log(res, 'success')
          if (res.data.resp){
            setKvmeyStatus(1)
          }else{
            setKvmeyStatus(-1)
          }
              
    
    
    })
      .catch(error => console.log(error, 'error'))





    }


    const amulafielAzkvnun = async (ramtun, llowzugun)=>{

      
      let a =[]
      Object.values(llowzugun).map((el)=>a.push(ramtun.ramtun[el-1]))
      

      if (!llowzugun){
          return; 
      }
      let lentotal=0;
      for (let index = 0; index < ramtun.res_len; index++) {
        lentotal= lentotal+ llowzugun["hemvl"+(index).toString()]
      }
      if (lentotal===0){
        return;
      }


      const resp = await httpClient.post(
        process.env.REACT_APP_API_URL+'/asses_ramtun', 
            {   
                "user":user,
                "ramtun_id":ramtun.id,
                "llowzugun": a

            },
            {   
                headers:
                    {
                        "Access-Control-Allow-Origin": "*"
                    }
            }
        ).then((res) => {//console.log(res, 'success')
          if (res.data.resp){
            setKvmeyStatus(1)
          }else{
            setKvmeyStatus(-1)
          }
              
    
    
    })
      .catch(error => console.log(error, 'error'))

          
      }


useEffect(() => {}, [fewlaRamtun])
useEffect(()=>{ 
},[kvmeyStatus])

  useEffect(() => {
    getRamtun();
}, [])

  return (
        <div style={{justifyItems:"center"}}>
          <Grid container spacing={1}sx={{justifyContent:"center",justifyItems:"center"}} >
          <Grid item xs={10} paddingBottom={3}>
            <Button size='small' variant='contained' onClick={()=>{chuchiPage(0)}}>
            <ArrowBackIcon fontSize='small'/>{mapuzugunPvneal? mapuzugun.back:wigkazugun.back}
            </Button>
          </Grid>
          {(!loadingRamtun )? <>
                  
                                        <Grid item xs={10} sx={{justifySelf:"center"}}>
                                          <Stack sx={{width:"100%", justifyContent:"center",justifySelf:"center"}}>
                                            <Card sx={{justifySelf:"center"}}>

                                            {ramtunList[fewlaRamtun].ramtunType===1?
                                                <RamtunType1Content ramtun={ramtunList[fewlaRamtun]} llowzugunAmulafiel={amulafielLlowzugun} llowzugulgewyi={kvmeyStatus!==0} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}/>
                                                :
                                                ramtunList[fewlaRamtun].ramtunType===2?
                                                <RamtunType2Content ramtun={ramtunList[fewlaRamtun]} amulafielGvtxam={amulafielGvtxam} llowzugulgewyi={kvmeyStatus!==0} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}/>
                                                :
                                                ramtunList[fewlaRamtun].ramtunType===3?
                                                <RamtunType3Content ramtun={ramtunList[fewlaRamtun]} amulafielGvtxam={amulafielAzkvnun} llowzugulgewyi={kvmeyStatus!==0} mapuzugun={mapuzugun} wigkazugun={wigkazugun} mapuzugunPvneal={mapuzugunPvneal}/>
                                                :
                                                null
                                            }
                                            </Card>
                                            {kvmeyStatus===1?

                                            
                                            <Card sx={{padding:4,marginTop:4, backgroundColor:"#8edc9b", borderColor:"#3a6942", color:"#3a6942", justifyItems:"center", display:"flex", flexDirection:"column", justifyContent:"center"}}>

                                                <Typography variant={"h4"} textAlign={"center"}>
                                                  {mapuzugunPvneal? mapuzugun.ramtun.kvmi:wigkazugun.ramtun.kvmi}

                                                </Typography>
                                                <Box
                                                    m={1}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                  >
                                                 <Button onClick={()=>{kvpalechikamtxipan()}} disabled={kvmeyStatus===0?true:false} variant='contained' sx={{maxWidth:"25%", marginTop:3}} >
                                                  {fewlaRamtun===ramtunList.length-1? "Txipayal" :"Küpalechi llowzugun!"}
                                                </Button>
                                                </Box>
                                            </Card>
                                            
                                            :null}

                                              {kvmeyStatus===-1?

                                            <Card sx={{paddingY:4, paddingX:10,marginTop:4, backgroundColor:"#f9adad", borderColor:"#5f2c2c", color:"#5f2c2c", justifyItems:"center", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                  
                                                  <Typography variant={"h4"} textAlign={"center"}>
                                                  {mapuzugunPvneal? mapuzugun.ramtun.kvmelai:wigkazugun.ramtun.kvmelai}
                                                  </Typography>
                                                  <Box
                                                    m={1}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                  >
                                                    <Button onClick={()=>{kvpalechikamtxipan()}} disabled={kvmeyStatus===0?true:false} variant='contained' sx={{maxWidth:"25%", marginTop:3}} >
                                                      {fewlaRamtun===ramtunList.length-1? <> {mapuzugunPvneal?mapuzugun.txipayal:wigkazugun.txipayal} </>:<>{mapuzugunPvneal?mapuzugun.ramtun.kvpalechi:wigkazugun.ramtun.kvpalechi} </>}
                                                    </Button>
                                                  </Box>
                                                  
                                              </Card>

                                              :null}



                                          </Stack>
                                        </Grid>
                          </>
                    :
                    <Igelal/>
                  
                    }
          </Grid>
        </div>
  )
}

export default Ramtun