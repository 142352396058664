import { Grid, Typography, Tooltip,IconButton } from '@mui/material'
import React from 'react'
import { useStyles } from './Styles';

import InfoIcon from '@mui/icons-material/Info';



                
export  const ChumteNewenGeiPass = ({level, mapuzugun, wigkazugun, mapuzugunPvneal }) => {
    const colorClass = useStyles[level]();
    
  return (
    
  
    <Grid container sx={{justifyContent:"center",justifyItems:'center', height:'5px', paddingLeft:5, paddingRight:5, borderColor:'black'}} spacing={1}>  
        
        <Grid item xs={2} className={level>=0?colorClass.gridStyle:null}>
            
        </Grid>
        <Grid item xs={2} className={level>=1?colorClass.gridStyle:null}>
            
        </Grid>
        <Grid item xs={2} className={level>=2?colorClass.gridStyle:null}>

        </Grid>
        <Grid item xs={2} className={level>=3?colorClass.gridStyle:null}>

        </Grid>
        <Grid item xs={2} className={level>=4?colorClass.gridStyle:null}>

        </Grid>
        <Grid item xs={2} className={level>=4?colorClass.gridStyle:null}>
        <Tooltip title={mapuzugunPvneal?mapuzugun.passnewen.kvmechi:wigkazugun.passnewen.kvmechi} placement="bottom">
          <IconButton>
          <InfoIcon />
          </IconButton>
        </Tooltip>
        </Grid>
        
        {level>-1? 
        
          <Grid item xs={10} sx={{justifyContent:"center",justifyItems:'center',width:'100%'}}>
            <Typography> {mapuzugunPvneal?mapuzugun.passnewen.message[level]:wigkazugun.passnewen.message[level]}</Typography>
          </Grid>
          
          :
            null
          }
          
       
    </Grid>
  )
}
