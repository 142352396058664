import { Card, Typography, Button, Stack } from "@mui/material"
import React from "react"


const KonalChateam = ({amualChateam, mapuzugun, wigkazugun, mapuzugunPvneal, paragraphStyle, catchSentenceStyle})=>{

    const clickHandle = ()=>{
        amualChateam(2)
    }
    return(
        <Card sx={{margin:2,padding:4}}>
            <Stack spacing={4} >
            <Typography variant="body1" style={catchSentenceStyle}>
            {mapuzugunPvneal?mapuzugun.konalChat.title:wigkazugun.konalChat.title}
               
            </Typography>
            <Typography variant="body1" style={paragraphStyle}>
            {mapuzugunPvneal?mapuzugun.konalChat.des:wigkazugun.konalChat.des}
                
            </Typography>
            <Button variant="contained" onClick={clickHandle}>
            {mapuzugunPvneal?mapuzugun.konalChat.amualbut:wigkazugun.konalChat.amualbut}
            </Button>
            </Stack>
        </Card>
        
    )

}




export  default KonalChateam