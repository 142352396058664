import React, {useState,useEffect} from 'react';
import httpClient from '../../HttpClient';
import { Typography,TextField, Stack, Button ,Grid } from '@mui/material';
import Igelal from '../../IngelalPage/Igelal';


const TukupatulanKontrasena = ({mapuzugun, wigkazugun, mapuzugunPvneal, loadingZugun,setZugun, setLoadingZugun,user}) => {
    const [email, setEmail] = useState('')
    const [sendedIfExists, setSendedIfExists] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
      //console.log(user)
      console.log('PASO USEFFECT',user)      
      console.log(location)
      if (location.state &&  location.state.redir ){
        console.log('REDIR ?SI ')
        mapuzugun = location.state.mapuzugun
        wigkazugun = location.state.wigkazugun
        mapuzugunPvneal = location.state.mapuzugunPvneal
        loadingZugun = location.loadingZugun
        setLoading(loadingZugun)
      }else {
          console.log('REDIR ?NO ')
          const respz =  httpClient.get(process.env.REACT_APP_API_URL+'/epuzugun').then((res) => {//console.log(res, 'success')
          //console.log(res)
          setZugun(res.data.mapuzugun,res.data.wigkazugun)
          setLoading(false)
          setLoadingZugun(false)
          
          })
            .catch(error => console.log(error, 'error'))

        const resp =  httpClient.get(process.env.REACT_APP_API_URL+'/@me',
                            {headers:{"Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}})
                            .then((res) => {console.log( res,'success');}).catch(error => console.log(error, 'error') )
        
        //console.log(resp)
        
       
        
      }


      ;
  },[])
  



    const passRequest= async ()=>{
      try{
        const resp = await httpClient.post(process.env.REACT_APP_API_URL+'/send_reset_mail',
        {
          email:email,
        });
        console.log(resp.status, resp.statusText)
        setSendedIfExists(true)
      }
      catch(error){
        if (error.response.status === 401){
            setSendedIfExists(false);        
        }
      }
    };

  return (
    
    <Grid container spacing={2}
    sx={{
      display:'flex',
      width: '100%',
      height:'100%',
      alignItems: 'center',
      flexDirection:'row',
    }}
    
   
  >
    <Grid item xs={6} md={7}>
      <Grid container>
      <Grid  item   sx={{
          padding:5,
        justifyItems:'start',
          alignItems:'center',
          height:'100%',
          maxHeight: '80%',
          width:'auto'
          }}
          
      >
        
          <img src={`${process.env.PUBLIC_URL}/imgs/back.png`} alt='background' style={{width:"70%", maxHeight:"70vh",alignSelf:'center'}}/>
      
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={6} md={5}>

      <Stack component="form"
      spacing={2}
      noValidate
      autoComplete="off"
      sx={{marginRight:15,
        marginBottom:15,
         justifyItems:'center',
          alignItems:'center',
          width: 'auto',
          minWidth: '280px',
          maxWidth: '330px'}}
      
    > <>
     {!loading & !loadingZugun?<> 
        {!sendedIfExists?
        <>
            <img src={`${process.env.PUBLIC_URL}/imgs/logo.svg`} alt='background'  style={{width:'30%',alignSelf:'center'}}/>
            <Typography variant='h4'>
            {mapuzugunPvneal?mapuzugun.tukulpatulan.title:wigkazugun.tukulpatulan.title}
            </Typography>
            <TextField
                fullWidth
                required
                id="email"
                label="Email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                />
        
        <Button variant="contained" onClick={passRequest} fullWidth > {mapuzugunPvneal?mapuzugun.tukulpatulan.amulalbut:wigkazugun.tukulpatulan.amulalbut}  </Button></>:
        <>
            <Typography>{mapuzugunPvneal?mapuzugun.tukulpatulan.nielmi:wigkazugun.tukulpatulan.nielmi}</Typography>
            
            <Button variant="contained" onClick={()=>{window.location.href='/konal'}} fullWidth >{mapuzugunPvneal?mapuzugun.tukulpatulan.konmealbut:wigkazugun.tukulpatulan.konmealbut}</Button>
        </>
            }
            </>
            :<><Igelal/></>}</>
        </Stack>
      </Grid>
    </Grid>

  )
}

export default TukupatulanKontrasena