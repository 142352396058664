function convertBase64TextToBinary(base64text) {
    let raw = window.atob(base64text);  
    let rawLength = raw.length;
    let arr = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      arr[i] = raw.charCodeAt(i);
    }
    return arr;
}


const imageConverter = (base64Img) =>  {
    let decodedData = convertBase64TextToBinary(base64Img)
    let imgBlob = new Blob([decodedData], {type: 'image/jpeg'})
    let imgUrl = URL.createObjectURL(imgBlob)
    return imgUrl
  }

export {  convertBase64TextToBinary,
          imageConverter
  }