import { Card, Typography, Button, Stack } from "@mui/material"
import React from "react"


const KonalTxokinGvtxam = ({amualTxokinGvtxam,mapuzugun, wigkazugun, mapuzugunPvneal, paragraphStyle, catchSentenceStyle})=>{

    const clickHandle = ()=>{
        amualTxokinGvtxam(3)
    }
    return(
        <Card sx={{margin:2,padding:4}}>
            <Stack spacing={4} >
            <Typography variant="body1" style={catchSentenceStyle}>
                {mapuzugunPvneal?mapuzugun.konalGvtxam.title:wigkazugun.konalGvtxam.title}

            </Typography>
            <Typography variant="body1" style={paragraphStyle}>
                {mapuzugunPvneal?mapuzugun.konalGvtxam.des:wigkazugun.konalGvtxam.des}
            </Typography>
            <Button variant="contained" onClick={clickHandle}>
                {mapuzugunPvneal?mapuzugun.konalGvtxam.amualbut:wigkazugun.konalGvtxam.amualbut}

            </Button>
            </Stack>
        </Card>
        
    )

}




export  default KonalTxokinGvtxam