import { Grid, Typography,Card, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import httpClient from "../../../../HttpClient";
import Igelal from "../../../../IngelalPage/Igelal";


const CheStatistics = ({antv, chumtenRamtun, kvmelkalelu, wezalkalelu,mapuzugun, wigkazugun, mapuzugunPvneal, paragraphStyle, catchSentenceStyle}) => {
    
    const [loadingStats,setLoadingStats] = useState(true)
    const [stats,setStats] = useState(null)


    const getStats = ()=>{
        const resp =  httpClient.get(process.env.REACT_APP_API_URL+'/tani_stats',{headers:{"Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}}
        ).then(
            (res) => {//console.log( res,'success')
                    setStats(res.data)
                    setLoadingStats(false)
                    }
            ).catch(
                error => console.log(error, 'error'))
    }

    useEffect(()=>{
        getStats()
    },[])   

    useEffect(()=>{},[stats])


    return (
        <Card sx={{margin:2,padding:4}}>
            <Stack spacing={1} >
                <Typography variant="body1" style={catchSentenceStyle}>
                {mapuzugunPvneal?mapuzugun.stats.title:wigkazugun.stats.title}

                    
                </Typography>

                    {loadingStats? <Igelal/>:
                        <Grid container spacing={2}>
                            
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" style={catchSentenceStyle}> {stats.antv} </Typography>
                                <Typography variant="body1" style={paragraphStyle}> {mapuzugunPvneal?mapuzugun.stats.antv:wigkazugun.stats.antv} </Typography>
                                
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" style={catchSentenceStyle}> {stats.kvmelkalelu+stats.wezalkalelu} </Typography>
                                <Typography variant="body1" style={paragraphStyle}> {mapuzugunPvneal?mapuzugun.stats.chumtenRamtun:wigkazugun.stats.chumtenRamtun} </Typography>
                                
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" style={catchSentenceStyle} sx={{color:"#3a6942"}}> {stats.kvmelkalelu} </Typography>
                                <Typography variant="body1" style={paragraphStyle}> {mapuzugunPvneal?mapuzugun.stats.kvmelkalelu:wigkazugun.stats.kvmelkalelu} </Typography>
                                
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                <Typography variant="body1" style={catchSentenceStyle} sx={{color:"#5f2c2c"}}> {stats.wezalkalelu} </Typography>
                                <Typography variant="body1" style={paragraphStyle}> {mapuzugunPvneal?mapuzugun.stats.wezalkalelu:wigkazugun.stats.wezalkalelu} </Typography>
                                    
                            </Grid>


                        </Grid>
                    }

            </Stack>
        </Card>
        )
}

export default CheStatistics