import { Card, Grid, Button, Typography, Divider, List, ListItem, Box, Modal, TextField, Container, Pagination} from "@mui/material";
import React, {useEffect, useState} from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Chip from '@mui/material/Chip';
import httpClient from '../../../../HttpClient';

const TxokinGvtxamRuka = ({user,chuchiPage,mapuzugun, wigkazugun, mapuzugunPvneal})=>{

    const [msgs, setMsgs] = useState([]);
    const [gvlalelu, setGvlalelu] = useState(null);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1)
    const [npage, setNPages] = useState(0)
    const [winolzugual, setWinolzugual] = useState(false)
    const [winolzugunGvtxam, setWinolzugunGvtxam] =useState('') 
    const [title, setTitle] = useState('');
    const [gvtxam, setGvtxam] = useState('');


 
    const handleClickOpen = (key)=>{
        if (key === gvlalelu){
            setGvlalelu(null)
        }else{
            setGvlalelu(key)
        }
        
    };

    const handleOpen = () => {
            setOpen(true)
        }

    const handleClose = () => {
        setOpen(false)
        }

    const handleCloseWinolzugun = ()=>{
        setWinolzugual(false)
    }

    const sendWeGvtxam =  async () =>{
        if (title.length<=0 & gvtxam.length<=0){
            alert('Müli tami wirintukuafiel tami gvtxam')
            return 
        }

        const resp = await httpClient.post(
                    process.env.REACT_APP_API_URL+'/create_gvtxam', 
                    {   
                        "user":user,
                        "titulo":title,
                        "gvtxam":gvtxam
                    },
                    {   
                        headers:
                            {
                                "Access-Control-Allow-Origin": "*"
                            }
                    }
            )
            if (resp.data){
                console.log(resp.status)
            }
            setGvtxam('')
            setTitle('')
            getGvtxam(page)
            setOpen(false)
        }

        const sendWeWinolzugunGvtxam =  async (gid) =>{
            if ( winolzugunGvtxam.length<=0){
                alert('Müli tami wirintukuafiel tami winolzugun')
                return 
            }
    
            const resp = await httpClient.post(
                process.env.REACT_APP_API_URL+'/create_winolzugun_gvtxam', 
                        {   
                            "user":user,
                            "gvtxam":winolzugunGvtxam,
                            "gvtxam_id":gid
                        },
                        {   
                            headers:
                                {
                                    "Access-Control-Allow-Origin": "*"
                                }
                        }
                )
                if (resp.data){
                    console.log(resp.status)
                }
            setWinolzugunGvtxam('')
            getGvtxam()
            setWinolzugual(false)

            }


        const handleChange = (e,setFcn)=>{
            setFcn( e.target.value)
          }
        const handlePageChange = (e,value)=>{
            setPage(value)
            
        }

    const getGvtxam = async  () =>{
        const n = 5
        const resp = await httpClient.post(
            process.env.REACT_APP_API_URL+'/get_trokin_gvtxam', 
            {   
                "user":user,
                "n":n,
                "offset":(page - 1 ) * n
            },
            {   
                headers:
                    {
                        "Access-Control-Allow-Origin": "*"
                    }
            }
            
            
    )
                if (resp.data){
                    //console.log(resp.data)
                    setMsgs(resp.data.msgs)
                    setNPages(resp.data.npages)
                }else{
                    console.log('no se pudieron cargar los mensajes ')
                }
    }

    useEffect(()=>{},[gvlalelu,title,gvtxam])
    useEffect(()=>{getGvtxam()},[page,npage])
    
    useEffect(()=>{
        
        getGvtxam()

        


    },[])

    return(
        <>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            
                            borderRadius:'20px',
                            boxShadow: 24,
                            p: 4,
                            }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {mapuzugunPvneal?mapuzugun.gvtxam.wegvtxamtitle:wigkazugun.gvtxam.wegvtxamtitle}
                        
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {mapuzugunPvneal?mapuzugun.gvtxam.wevy:wigkazugun.gvtxam.wevy}
                        
                    </Typography>
                    <TextField  id="outlined-basic" 
                                label="" 
                                variant="outlined" 
                                fullWidth 
                                value={title} 
                                onChange={(e)=>{handleChange(e,setTitle)}} 
                                />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {mapuzugunPvneal?mapuzugun.gvtxam.wegvtxam:wigkazugun.gvtxam.wegvtxam}
                        
                    </Typography>
                    <TextField  id="outlined-basic" 
                                label="" 
                                variant="outlined" 
                                fullWidth 
                                value={gvtxam}
                                rows={6}
                                multiline
                                onChange={(e)=>{handleChange(e,setGvtxam)}} 
                                maxRows={10}/>
                    
                    
                        <Container sx={{display:"flex",justifyItems:"center", justifyContent:"center", marginTop:3}}>
                            <Button  variant='contained' onClick={sendWeGvtxam}> {mapuzugunPvneal?mapuzugun.gvtxam.wegvtxambut:wigkazugun.gvtxam.wegvtxambut}</Button>
                        </Container>
                        <Container sx={{display:"flex",justifyItems:"center", justifyContent:"center", marginTop:3}}>
                            <Button  variant='contained' onClick={handleClose} color='secondary'>{mapuzugunPvneal?mapuzugun.gvtxam.wiñotual:wigkazugun.gvtxam.wiñotual}</Button>
                        </Container>
                    
                </Box>
            </Modal>


        <Grid container spacing={1}sx={{justifyContent:"center",justifyItems:"center"}} >
        <Grid item xs={1} >
                
                </Grid>      
            <Grid item xs={3} paddingBottom={3}>
                <Button size='small' variant='contained' onClick={()=>{chuchiPage(0)}}>
                    <ArrowBackIcon fontSize='small'/>{mapuzugunPvneal?mapuzugun.back:wigkazugun.back}
                </Button>
            </Grid>      
            <Grid item xs={4} >
                
            </Grid>      
            <Grid item xs={3} paddingBottom={3} justifyItems={"end"}>
                <Button  variant='contained'  onClick={handleOpen}>{mapuzugunPvneal?mapuzugun.gvtxam.wegvtxambut:wigkazugun.gvtxam.wegvtxambut}</Button>
            </Grid>      
            <Grid item xs={10}>
                <Card>
                    <Grid container spacing={1}>
                        {
                            msgs.map((el)=>{

                                return( 
                                    <Grid item xs={12} margin={3} key={el.msgId}>
                                        
                                        <Grid container >
                                            <Grid item xs={10}>
                                                <Typography sx={{textDecorationLine: 'underline'}} variant="h5"   onClick={()=> {handleClickOpen(el.msgId)}}  component="span">
                                                    {el.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} >
                                                <Typography onClick={()=> {handleClickOpen(el.msgId)}} component="span">
                                                    <Chip label={el.created_at}  /> 
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography  variant="body1"   onClick={()=> {handleClickOpen(el.msgId)}}  component="span"    width={"100%"}>
                                                </Typography>
                                                    <Chip label = {(mapuzugunPvneal?mapuzugun.gvtxam.wirintukueyu:wigkazugun.gvtxam.wirintukueyu ) +": " +el.username}/>{}
                                                    
                                            </Grid>
                                        </Grid>
                                        {gvlalelu===el.msgId ?null:<Divider/>}
                                        
                                        



                                            {gvlalelu===el.msgId ?
                                            <>
                                                <Typography variant="body1" paragraph sx={{border:1, paddingY:2, paddingX:1, borderRadius:2, backgroundColor:"#b8e7ed"}}>
                                                {el.msg}
                                                </Typography>
                                                    
                                                        <List>
                                                            {el.llz.length>0?   
                                                        
                                                                el.llz.map((ll)=>{
                                                                    return (
                                                                        <>
                                                                        < ListItem key={ll.llzId} xs={12} margin={1} sx={{borderColor:"grey"}} spàcing={3} >
                                                                                <Grid container >

                                                                                    <Grid item xs={10}>
                                                                                        <Typography  variant="body1"  component="span"    width={"100%"}>
                                                                                        </Typography>
                                                                                        <Chip label = 
                                                                                        {mapuzugunPvneal?mapuzugun.gvtxam.wirintukueyu+": " +ll.username :wigkazugun.gvtxam.wirintukueyu+": " +ll.username} />
                                                                                    </Grid>

                                                                                    <Grid item xs={2}>
                                                                                        
                                                                                        <Chip label={ll.created_at}/> 
                                                                                        
                                                                                    </Grid>
                                                                                    
                                                                                    <Grid item xs={12} sx={{border:1, borderRadius:20, paddingY:2, paddingX:1,backgroundColor:"white"}}>
                                                                                        <Typography  variant="body1"     component="span"    width={"100%"}  padding={2}>
                                                                                        {ll.msg}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </ListItem>
                                                                            
                                                                        </>
                                                                        )
                                                                    }
                                                                )
                                                            
                                                            :
                                                                <Grid container >

                                                                    <Grid item xs={12} sx={{border:1,borderRadius:3, paddingY:2, paddingX:1, marginX:2,backgroundColor:"#b8e7ed"}}>
                                                                        <Typography  variant="body1"     component="span"    width={"90%"} >
                                                                            {mapuzugunPvneal?mapuzugun.gvtxam.nogvtxam:wigkazugun.gvtxam.nogvtxam}
                                                                            
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        
                                                        </List>
                                                        
                                                        {winolzugual?<>
                                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                            {mapuzugunPvneal?mapuzugun.gvtxam.wewgvtxamtitle:wigkazugun.gvtxam.wewgvtxamtitle}
                                                            
                                                        </Typography>
                                                        <TextField  id="outlined-basic" 
                                                                    label="" 
                                                                    variant="outlined" 
                                                                    fullWidth 
                                                                    value={winolzugunGvtxam}
                                                                    rows={2}
                                                                    multiline
                                                                    onChange={(e)=>{handleChange(e,setWinolzugunGvtxam)}} 
                                                                    maxRows={10}/>
                                                        <Container sx={{display:"flex",justifyItems:"center", justifyContent:"end", marginTop:1}}>
                                                            <Button  size="small" variant='contained' onClick={()=>{sendWeWinolzugunGvtxam(el.msgId)}}>{mapuzugunPvneal?mapuzugun.gvtxam.wegvtxambut2:wigkazugun.gvtxam.wegvtxambut2}</Button>
                                                        
                                                            <Button  size="small" variant='contained' onClick={handleCloseWinolzugun} color='secondary'>{mapuzugunPvneal?mapuzugun.gvtxam.wiñotual:wigkazugun.gvtxam.wiñotual}</Button>
                                                        </Container>
                                                        
                                                        </> : 
                                                        <Container sx={{display:"flex",justifyItems:"center", justifyContent:"center", marginTop:3}}>
                                                            <Button  size="small" variant='contained' onClick={()=>{setWinolzugual(true)}}>{mapuzugunPvneal?mapuzugun.gvtxam.winolzugual:wigkazugun.gvtxam.winolzugual}</Button>
                                                        </Container> 
                                                        
                                                        }
                                                        
                                                        
                                                    </>
                                            :null    
                                            }
                                        
                                    </Grid>
                                )
                            }
                                    )
                        }
                    </Grid>
                    <Container sx={{display:"flex",justifyItems:"center", justifyContent:"center", marginTop:3, marginBottom:3}}>
                        <Pagination count={npage} variant="outlined" color="primary"  page={page} onChange={handlePageChange} />
                    </Container>
                    
                </Card>
    
            </Grid>        
        </Grid>        
        </>
    )

}


export default TxokinGvtxamRuka